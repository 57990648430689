import { userAxios } from "../../index";

const getCupon = async (data) => {
  try {
    return await userAxios
      .post(`cupons/get-cupon`, data)
      .then((res) => res.data);
  } catch (error) {
    console.log(error);
  }
};

const cashView = async (id) => {
  try {
    return await userAxios.get(`cashControl/${id}`).then((res) => res.data);
  } catch (error) {
    console.log(error);
  }
};

const cashGlobalClose = async (data) => {
  try {
    return await userAxios
      .post(`cashControl/clinicCash/closing`, data)
      .then((res) => res.data);
  } catch (error) {
    console.log(error);
  }
};

const cashGlobalDaily = async () => {
  try {
    return await userAxios.get(`cashControl/admin`).then((res) => res.data);
  } catch (error) {
    console.log(error);
  }
};

const cashGlobalHistory = async (id, perPage) => {
  try {
    return await userAxios
      .get(`cashControl/admin/clinic/${id}/${perPage}`)
      .then((res) => res.data);
  } catch (error) {
    console.log(error);
  }
};

const attachFile = async (data) => {
  try {
    return await userAxios
      .post(`cashControl/clinicCash/attach`, data)
      .then((res) => res.data);
  } catch (error) {
    console.log(error);
  }
};

export default {
  getCupon,
};
