<template>
  <div style="font-size: 115%">
    <div class="father">
      <div class="card" v-if="foundCampaign">
        <div class="image">
          <img src="@/assets/images/clinicaHispanaLogo.png" alt="" class='logo'>
        </div>
        <div class="card__info" v-if="stateCupon == 'idle'">
          <h1 class="title">Obten tu cupon!</h1>
          <b-input-group prepend="+1">
            <b-form-input placeholder="Escribe tu número de teléfono aquí!" v-model="formatPhone"
              @keyup.enter="getCode" />
          </b-input-group>
          <b-form-checkbox v-model="status" class="mt-5">
            He leido y estoy de acuerdo con los <a
              href="https://tuclinicahispanafamiliar.com/terminos-y-condiciones-de-uso/" target="_blank">terminos de
              uso</a> y la <a href="https://tuclinicahispanafamiliar.com/politica-privacidad/" target="_blank">politica de
              privacidad</a>.
          </b-form-checkbox>
          <b-button variant="warning" id="buttonCupon" class="mt-6 buttonTemplate" @click="getCode" :disabled="!status">
            <span style="color: black;">
              Obten tu Cupon!
            </span>
          </b-button>
        </div>
        <div class="card__info" v-else-if="stateCupon == 'sending'">
          <h1 class="title">Enviando el cupon!</h1>
          <div class="fatherSpinner">
            <b-spinner variant="warning" type="grow" label="Spinning" />
          </div>
        </div>
        <div class="card__info" v-else-if="stateCupon == 'send'">
          <h1 class="title">Cupon enviado!</h1>
          <div class="fatherSpinner">
            <b-spinner variant="warning" type="grow" label="Spinning" />
          </div>
        </div>
      </div>
      <div class="card" v-else>
        <div class="image">
          <img src="@/assets/images/clinicaHispanaLogo.png" alt="" class='logo'>
        </div>
        <div class="card__info">
          <h1 class="title">La campaña no fue encontrada o ha expirado!</h1>
        </div>
      </div>
      <b-modal ref="my-modal" title="Your privacity" hide-header-close>
        This website uses cookies to ensure that you get the best experience on our website. By clicking the "OK" button,
        you affirm that you agree with this.
        <template #modal-footer="{ ok }">
          <a href="https://tuclinicahispanafamiliar.com/politica-de-cookies/" target="_blank">
            <b-button size="sm" variant="secondary">
              Cookie policies.
            </b-button>
          </a>
          <b-button size="sm" variant="success" @click="ok()">
            OK
          </b-button>
        </template>
      </b-modal>
    </div>
    <strong class="info">Para más información llámanos al <a href="tel:+12148889000">(214) 888-9000</a></strong>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" class="bottom">
      <path fill="#eeac79" fill-opacity="0.2"
        d="M0,128L60,117.3C120,107,240,85,360,112C480,139,600,213,720,250.7C840,288,960,288,1080,245.3C1200,203,1320,117,1380,74.7L1440,32L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z">
      </path>
    </svg>
    <noscript><img height="1" width="1" style="display:none"
        src="https://www.facebook.com/tr?id=254444487646876&ev=PageView&noscript=1" /></noscript>
  </div>
</template>

<script>
import axios from "@/core/services/api/landings/cupons";
import { BInputGroup, BFormInput, BButton, BFormCheckbox, BModal } from "bootstrap-vue"

export default {
  components: {
    BFormInput,
    BButton,
    BFormCheckbox,
    BModal,
    BInputGroup
  },
  computed: {
    formatPhone: {
      get() {
        let phone = this.phone;
        if (phone) {
          phone = phone.toString().replace(/\D/g, "");
          const match = phone.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/);
          if (match) {
            phone = `(${match[1]}${match[2] ? "" : ""}) ${match[2]}${match[3] ? "-" : ""
              }${match[3]}`;
          }
          return phone;
        } else {
          return "";
        }
      },
      set(val) {
        this.phone = val;
      },
    },
  },
  data() {
    return {
      phone: '',
      foundCampaign: true,
      stateCupon: 'idle',
      status: false,
    };
  },
  mounted() {
    // facebook tracking service: https://developers.facebook.com/docs/meta-pixel/implementation/conversion-tracking
    !function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod ?
          n.callMethod.apply(n, arguments) : n.queue.push(arguments)
      };
      if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
      n.queue = []; t = b.createElement(e); t.async = !0;
      t.src = v; s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

    fbq('init', '254444487646876');
    fbq('track', 'PageView');

    const btn = document.getElementById("buttonCupon");
    btn.addEventListener("click", (e) => {
      fbq('trackCustom', 'Obtener_cupon');
    });
  },
  methods: {
    getCode() {
      if (this.phone) {
        this.stateCupon = 'sending'
        axios.getCupon({ campaign: this.$route.params.cupon, phone_number: `+1${this.phone}` }).then(({ status }) => {
          if (status) return this.stateCupon = 'send'
          this.foundCampaign = false
          return location.reload();
        }).catch(() => {
          return this.foundCampaign = false
        })

      }
    }
  },
};
</script>
<style lang="scss" scoped>
@import './styles.css';
</style>
